import { ICounter } from '../../utils/types/leave';
import { IErrors } from '../../utils/types/types';
import { IToggleEnableUser, IUpdateUser, IUser } from '../../utils/types/user';
import { GET_USER, GET_USER_ERROR, GET_USER_SUCCESS, IGetListUsersAction, IGetListUsersErrorAction, IGetListUsersSuccessAction, IGetUserAction, IGetUserErrorAction, IGetUserSuccessAction, IToggleEnableUserAction, IToggleEnableUserErrorAction, IToggleEnableUserSuccessAction, IUpdateUserAction, IUpdateUserErrorAction, IUpdateUserSuccessAction, IUserGetCounterAction, IUserGetCounterErrorAction, IUserGetCounterSuccessAction, IUserGetMeAction, IUserGetMeErrorAction, IUserGetMeSuccessAction, IUserLoggedOutAction, TOGGLE_ENABLE_USER, TOGGLE_ENABLE_USER_ERROR, TOGGLE_ENABLE_USER_SUCCESS, USERS_GET_LIST, USERS_GET_LIST_ERROR, USERS_GET_LIST_SUCCESS, USER_GET_COUNTER, USER_GET_COUNTER_ERROR, USER_GET_COUNTER_SUCCESS, USER_GET_ME, USER_GET_ME_ERROR, USER_GET_ME_SUCCESS, USER_LOGGED_OUT, USER_UPDATE, USER_UPDATE_ERROR, USER_UPDATE_SUCCESS } from "./types";

/**
 * List users
 */
export const getListUsers = (): IGetListUsersAction => ({
  type: USERS_GET_LIST,
});

export const getListUsersSuccess = (users: IUser[]): IGetListUsersSuccessAction => ({
  type: USERS_GET_LIST_SUCCESS,
  payload: users,
});

export const getListUsersError = (errors: IErrors): IGetListUsersErrorAction => ({
  type: USERS_GET_LIST_ERROR,
  payload: errors,
});

/**
 * GET USER
 */
export const getUser = (id: string): IGetUserAction => ({
  type: GET_USER,
  payload: id,
});

export const getUserSuccess = (user: IUser): IGetUserSuccessAction => ({
  type: GET_USER_SUCCESS,
  payload: user,
});

export const getUserError = (errors: IErrors): IGetUserErrorAction => ({
  type: GET_USER_ERROR,
  payload: errors,
});

/**
 * Update user
 */
export const updateUser = (user: IUpdateUser): IUpdateUserAction => ({
  type: USER_UPDATE,
  payload: user,
});

export const updateUserSuccess = (): IUpdateUserSuccessAction => ({
  type: USER_UPDATE_SUCCESS,
});

export const updateUserError = (errors: IErrors): IUpdateUserErrorAction => ({
  type: USER_UPDATE_ERROR,
  payload: errors,
});

/**
 * Enable/Disable user
 */
export const toggleEnableUser = (enableTerms: IToggleEnableUser): IToggleEnableUserAction => ({
  type: TOGGLE_ENABLE_USER,
  payload: enableTerms,
});

export const toggleEnableUserSuccess = (): IToggleEnableUserSuccessAction => ({
  type: TOGGLE_ENABLE_USER_SUCCESS,
});

export const toggleEnableUserError = (errors: IErrors): IToggleEnableUserErrorAction => ({
  type: TOGGLE_ENABLE_USER_ERROR,
  payload: errors,
});

/**
 * Connected user
 */
export const getMe = (): IUserGetMeAction => ({
  type: USER_GET_ME,
});

export const getMeSuccess = (me: IUser): IUserGetMeSuccessAction => ({
  type: USER_GET_ME_SUCCESS,
  payload: me,
});

export const getMeError = (errors: IErrors): IUserGetMeErrorAction => ({
  type: USER_GET_ME_ERROR,
  payload: errors,
});

// User logout
export const userLoggedOut = (): IUserLoggedOutAction => ({
  type: USER_LOGGED_OUT,
});

/**
 * Connected user counters=
 */
export const meGetCounter = (id: string): IUserGetCounterAction => ({
  type: USER_GET_COUNTER,
  payload: id,
});

export const meGetCounterSuccess = (counter: ICounter): IUserGetCounterSuccessAction => ({
  type: USER_GET_COUNTER_SUCCESS,
  payload: counter,
});

export const meGetCounterError = (errors: IErrors): IUserGetCounterErrorAction => ({
  type: USER_GET_COUNTER_ERROR,
  payload: errors,
});
