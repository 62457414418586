import { api } from ".";
import { IListLeavesFilter } from "../store/leave/types";
import { generateFilterUrl } from "../utils/axios-utils";
import { ICreateDepartment } from "../utils/types/department";
import { ICreateLeave, IGetMyLeavesWithFilters, ILeaveDecision } from "../utils/types/leave";
import { IUpdateUser } from "../utils/types/user";

/**
 * Users requests
 */
export const getMeRequest = () => api.get('/users/me');

export const getUsersRequest = () => api.get('/users');

export const getUserRequest = (id: string) => api.get(`/users/${id}`);

export const updateUserRequest = (user: IUpdateUser) => api.patch(`/users/${user.id}`, user);

export const getUserCounterRequest = (id: string) => api.get(`/user/${id}/counter`);

/**
 * Enable/Desable user requests
 */

export const toggleEnableUserRequest = (id: string) => api.patch(`/users/${id}/enabled`, {});

/**
 * Roles requests
 */
export const getRolesRequest = () => api.get('/roles');

/**
 * Departments requests
 */
export const addDepartmentRequest = (department: ICreateDepartment) => api.post('/departments', department);

export const getDepartmentsRequest = () => api.get('/departments');

export const getDepartmentTeamRequest = (id: string) => api.get(`/departments/${id}/users`);

export const updateDepartmentRequest = (department: ICreateDepartment) => api.patch(`/departments/${department.id}`, department);

export const getDepartmentByIdRequest = (id: string) => api.get(`/departments/${id}`);

/**
 * Leaves
 */
export const getLeavesRequest = (statusFilter?: IListLeavesFilter) => {
  const URLFilter = generateFilterUrl('/leaves', statusFilter);
  return api.get(URLFilter);
};

export const addLeaveRequest = (leave: ICreateLeave) => api.post('/leaves', leave);

export const getLeavesTypesRequest = () => api.get('/leave_types');

export const getLeaveByIdRequest = (id: string) => api.get(`/leaves/${id}`);

export const updateLeaveRequest = (leave: ICreateLeave) => api.patch(`/leaves/${leave.id}`, leave);

export const cancelLeaveRequest = (id: string) => api.patch(`/leaves/${id}`, {
  confirmed: false,
});

export const updateLeaveDecisionRequest = (leaveDecision: ILeaveDecision) => api.patch(`/leave_decisions/${leaveDecision.id}`, leaveDecision);

export const getMyLeaveRequest = (terms: IGetMyLeavesWithFilters) => {
  const URLFilter = generateFilterUrl(`/users/${terms.id}/leave_requests`, terms.filters);
  return api.get(URLFilter);
};
