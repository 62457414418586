import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Tooltip } from "antd";
import React, { useContext } from "react";
import { GoogleLogout } from "react-google-login";
import { MobileMode } from "../../../../components/DeviceDetector";
import { AuthContext } from "../../../../context/AuthContext";
import { useWindowResize } from "../../../../hooks";
import { IAuthContext } from '../../../../utils/types/types';
import { IMe } from '../../../../utils/types/user';
import { clientId } from "../../../../utils/vars";
import './app-header.less';
import { LeavesBalance } from './components/LeavesBalance/index';
import { Notifications } from './components/Notifications/index';


const { Header } = Layout;

interface IAppHeaderProps {
	me: IMe;
	menuToggled: boolean;
	handelMenuToggled: () => void;
	onLogoutClick: () => void;
};

export const AppHeader: React.FC<IAppHeaderProps> = ({
	me,
	menuToggled,
	handelMenuToggled,
	onLogoutClick,
}) => {
	const { isMobile } = useWindowResize();

	const { logout }: IAuthContext = useContext(AuthContext);

	return (
		<Header className='header site-layout-background'>
			<Row justify={isMobile ? 'space-between' : 'end'} gutter={16}>
				<MobileMode>
					<Col>
						{React.createElement(
							menuToggled ? MenuOutlined : MenuOutlined,
							{
								className: "trigger burger-icon",
								onClick: handelMenuToggled,
							}
						)}
					</Col>
				</MobileMode>
				{!isMobile && (
					<Col>
						<LeavesBalance counter={me.data?.counter} />
					</Col>
				)}
				<Col>
					<Notifications />
				</Col>
				<Col>
					<GoogleLogout
						clientId={clientId}
						render={renderProps => (
							<Tooltip title="Déconnexion" placement="bottomRight">
								<Button icon={<LogoutOutlined />} onClick={
									() => {
										renderProps.onClick();
										onLogoutClick();
									}
								} />
							</Tooltip>
						)}
						buttonText="Logout"
						onLogoutSuccess={logout}
					>
					</GoogleLogout>
				</Col>
			</Row>
		</Header >
	)
};
