import { AuditOutlined, EditOutlined, SettingOutlined, StopOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Table, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { ILeavesList, IUpdateLeaveDecision } from '../../../../../store/leave/types';
import { ILeave, ILeaveDecision } from '../../../../../utils/types/leave';
import { ModalLeaveDecision } from '../ModalLeaveDecision';

const { Text } = Typography;
const { Column } = Table;

interface IListLeavesProps {
  isMe: boolean;
  leaves: ILeavesList;
  leaveDecision: IUpdateLeaveDecision;
  role?: string;
  onUpdatedLeaveDecision: (leaveDecision: ILeaveDecision) => void;
}

export const ListLeaves: React.FC<IListLeavesProps> = ({
  isMe,
  leaves,
  leaveDecision,
  role,
  onUpdatedLeaveDecision,
}) => {

  const { data: listLeaves, isLoading } = leaves;

  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState<ILeave>();

  const getSelectedLeave = (idLeave: string, leaves: ILeave[]) => leaves.find(leave => leave.id === idLeave);

  const handleShowLeaveModal = (id: string) => {
    if (leaves) setSelectedLeave(getSelectedLeave(id, listLeaves));
    setModalVisible(true);
  }

  const onUpdateLeaveDecisionAction = (leaveDecision: ILeaveDecision) => {
    onUpdatedLeaveDecision(leaveDecision);
    setModalVisible(false);
  };

  return (
    <>
      <Table<ILeave>
        dataSource={listLeaves}
        loading={isLoading}
        scroll={{
          x: true,
          scrollToFirstRowOnChange: true,
        }}
        locale={{
          emptyText: <EmptyContent description="Aucune demande de congé" />,
        }}
        pagination={{
          hideOnSinglePage: true,
        }}
        rowKey={({ id }) => `key-${id}`}
      >
        <Column
          key="avatar"
          fixed="left"
          width={32}
          render={
            (leave) => (
              <Avatar
                shape="square"
                src={!!leave.user.avatar && leave.user.avatar.url}
                icon={!leave.user.avatar && <UserOutlined />}
              />
            )
          }
        />
        {
          !isMe && (
            <Column
              key="fullname"
              title="Employé(e)"
              render={
                (leave) => <Text key="fnameText">{leave.user.firstName} {leave.user.lastName}</Text>
              }
            />
          )
        }
        <Column
          key="startingDate"
          title="Début"
          render={
            (leave) => (
              <Badge count={leave.startingDateExact}>
                <Text key="SDate">{moment(leave.startingDate).format('DD/MM/YYYY')}</Text>
              </Badge>
            )
          }
        />
        <Column
          key="endingDate"
          title="Fin"
          render={
            (leave) => (
              <Badge count={leave.endingDateExact}>
                <Text key="SDate">{moment(leave.endingDate).format('DD/MM/YYYY')}</Text>
              </Badge>
            )
          }
        />
        <Column
          key="diff"
          title="Durée"
          render={
            (leave) => {
              return <Text key="diffDays">{leave.durationLeave} jr{leave.durationLeave > 1 ? 's' : ''}</Text>;
            }
          }
        />
        <Column
          key="resumptionDate"
          title="Reprise"
          render={
            (leave) => (
              <Badge count={leave.resumptionDateExact}>
                <Text key="SDate">{moment(leave.resumptionDate).format('DD/MM/YYYY')}</Text>
              </Badge>
            )
          }
        />
        <Column
          key="deposit"
          title="Déposée le"
          render={
            (leave) => <Text key="depDate">{moment(leave.createdAt).format('DD/MM/YYYY')}</Text>
          }
        />
        <Column
          key="status"
          title="Statut"
          render={
            (leave) => (
              <>
                {leave.confirmed ?
                  leave.status ?
                    <Tag color="green">Accepté</Tag>
                    : leave.status === null ?
                      <Tag color="gold">En attente</Tag>
                      : <Tag color="red">Refusé</Tag>
                  : <Tag color="volcano">Annulée</Tag>
                }
              </>
            )
          }
        />
        <Column
          key="atcions"
          title={<SettingOutlined />}
          fixed="right"
          width={32}
          render={
            (leave) => (
              <>
                {
                  leave.status !== null || !leave.confirmed ?
                    <Tooltip title="Cette demande est fermée." placement="topRight">
                      <Button type="ghost" icon={<StopOutlined />} disabled />
                    </Tooltip>
                    :
                    <Tooltip title={isMe ? 'Modifier votre demande' : 'Prendre une décision'}>
                      {
                        isMe ?
                          (
                            <Link to={`/leaves/update/${leave.id}`}>
                              <Button type="link" shape="circle" icon={<EditOutlined />} />
                            </Link>
                          ) : (
                            <Button icon={<AuditOutlined />} type='primary' key={leave.id} onClick={() => leave.id && handleShowLeaveModal(leave.id)} />
                          )
                      }
                    </Tooltip>
                }
              </>
            )
          }
        />
      </Table>

      {
        selectedLeave && <ModalLeaveDecision leave={selectedLeave} role={role} leaveDecision={leaveDecision} isModalVisible={isModalVisible} onUpdateLeaveDecisionAction={onUpdateLeaveDecisionAction} onCloseModal={setModalVisible} />
      }
    </>
  );
};
