import { Col, Layout, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DesktopMode } from '../../../components/DeviceDetector';
import { EmptyContent } from '../../../components/EmptyContent';
import { PageSkeleton } from '../../../components/PageSkeleton';
import { UserPreview } from '../../../components/UserPreview';
import { getDepartmentList } from '../../../store/department/actions';
import { getRoles } from '../../../store/role/actions';
import { GlobalState } from '../../../store/root-reducer';
import { getUser, updateUser } from '../../../store/user/actions';
import { IPageProps } from '../../../utils/types/types';
import { UpdateForm } from './components/UpdateForm';

interface IUpdateProfilePageProps extends IPageProps { }

const UpdateProfilePageInner: React.FC<IUpdateProfilePageProps & ConnectedProps<typeof connector>> = ({
  route,
  history,
  match,
  me,
  user,
  userUpdate,
  roles,
  department,
  getUser: getUserAction,
  updateUser: updateUserAction,
  getRoles: getRolesAction,
  getDepartmentList: getDepartmentListAction,
}) => {

  useEffect(() => {
    if (!match.params.id) history.push('/users');
    else getUserAction(match.params.id);
  }, [getUserAction, match, history]);

  useEffect(() => {
    getRolesAction();
  }, [getRolesAction]);

  useEffect(() => {
    getDepartmentListAction();
  }, [getDepartmentListAction]);

  if (!user.data) return <EmptyContent description="Aucun utilisateur n'a été trouvé" />;

  return (
    <PageSkeleton mainLayout={true} pageTitle={route.title} goBackTo='/users'>
      <Row gutter={18}>
        <Col lg={19} xs={24}>
          <Spin spinning={userUpdate.isLoading}>
            <Layout className="content-layout">
              <UpdateForm connectedUserRole={me.data?.role.slug} departments={department} roles={roles} user={user.data} onUpdateFormSubmitted={updateUserAction} />
            </Layout>
          </Spin>
        </Col>
        <DesktopMode>
          <Col span={5}>
            <UserPreview user={user.data} upTitle={
              <Row gutter={[16, 16]} justify="center">
                <Col>
                  <h4>Profil actuel</h4>
                </Col>
              </Row>
            } />
          </Col>
        </DesktopMode>
      </Row>
    </PageSkeleton>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  me: state.users.me,
  user: state.users.user,
  userUpdate: state.users.update,
  roles: state.roles,
  department: state.department,
});

const mapDispatchToProps = {
  getUser,
  getRoles,
  getDepartmentList,
  updateUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UpdateProfilePage = connector(UpdateProfilePageInner);
