import { Button, Col, DatePicker, Divider, Form, Input, Radio, Row, TimePicker } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useState } from 'react';
import { ILeaveById } from '../../../../../store/leave/types';

moment.locale('fr');

const { Item } = Form;

interface IUpdateExceptionalLeaveFormProps {
  leave: ILeaveById | null;
}

export const UpdateExceptionalLeaveForm: React.FC<IUpdateExceptionalLeaveFormProps> = () => {

  const [excpLeaveDate, setExcpLeaveDate] = useState<moment.Moment | null>(null);
  const [isComingBack, setCompingBack] = useState(false);
  const [excpLeaveRepriseTime, setExcpLeaveRepriseTime] = useState<moment.Moment | null>(null);

  const handleExceptionalLeaveDateChange = (date: moment.Moment | null) => setExcpLeaveDate(date);
  const handleExcepLeaveRepriseOptionChange = ({ target }: RadioChangeEvent) => setCompingBack(target.value);
  const handleExceptionalLeaveRepriseChange = (time: moment.Moment | null, dateString: string) => setExcpLeaveRepriseTime(time);

  return (
    <Form layout="vertical">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Sollicite une sortie exceptionnelle</Divider>
        </Col>
      </Row>
      <Row gutter={[16, 16]} align="bottom">
        <Col lg={6} xs={24}>
          <DatePicker
            showTime
            showHour
            format={'HH:mm'}
            onChange={handleExceptionalLeaveDateChange}
            placeholder="Date / heure de sortie"
          />
        </Col>
        <Col lg={8} xs={24}>
          <Item name="excepLeaveRepriseOption">
            <Radio.Group onChange={handleExcepLeaveRepriseOptionChange}>
              <Radio value={true}>Avec retour</Radio>
              <Radio value={false}>Sans retour dans la journée</Radio>
            </Radio.Group>
          </Item>
        </Col>
        {
          isComingBack && (
            <Col lg={6} xs={24}>
              <TimePicker
                showHour
                format={'HH:mm'}
                onChange={handleExceptionalLeaveRepriseChange}
                placeholder="Heure de retour"
              />
            </Col>
          )
        }
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Motif de sortie exceptionnelle</Divider>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={8} xs={24}>
          <Item
            name="excepLeaveJustification"
            label="Justification"
            rules={[
              { required: true, message: 'Champs requis !' }
            ]}
          >
            <Input.TextArea placeholder="Justification..." autoSize={{ minRows: 2, maxRows: 6 }} />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col>
          <Button type="primary">Modifier la demande</Button>
        </Col>
      </Row>
    </Form >
  );
};
