import {
  applyMiddleware, createStore, Middleware
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { watchAddDepartment, watchGetDepartmentById, watchGetListDepartments, watchGetUsersListDepartment, watchUpdateDepartment } from './department/sagas';
import { watchAddLeave, watchCancelLeave, watchGetLeaveById, watchGetLeaves, WatchGetLeavesTypes, watchGetMyLeaves, watchUpdateLeave, watchUpdateLeaveDecision } from './leave/sagas';
import { watchGetRoles } from './role/sagas';
import rootReducer from './root-reducer';
import { watchGetMe, watchGetMeCounter, watchGetUser, watchGetUsers, watchToggleEnableUser, watchUpdateUser } from './user/sagas';

function* rootSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchGetUser),
    fork(watchGetRoles),
    fork(watchAddDepartment),
    fork(watchUpdateDepartment),
    fork(watchGetListDepartments),
    fork(watchUpdateUser),
    fork(watchToggleEnableUser),
    fork(watchGetMe),
    fork(watchGetUsersListDepartment),
    fork(WatchGetLeavesTypes),
    fork(watchAddLeave),
    fork(watchGetMyLeaves),
    fork(watchGetLeaves),
    fork(watchGetLeaveById),
    fork(watchUpdateLeave),
    fork(watchCancelLeave),
    fork(watchUpdateLeaveDecision),
    fork(watchGetDepartmentById),
    fork(watchGetMeCounter),
  ]);
}

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware: Middleware[] = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middleware);
  const composeEnhancers = composeWithDevTools({});

  const store = createStore(rootReducer, composeEnhancers(middlewareEnhancer));
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore;
