import { Layout, Spin } from "antd";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { PageSkeleton } from "../../../components/PageSkeleton";
import { addDepartment } from '../../../store/department/actions';
import { GlobalState } from "../../../store/root-reducer";
import { getListUsers } from "../../../store/user/actions";
import { hasPermission } from "../../../utils/permissions-utils";
import { ICreateDepartment } from '../../../utils/types/department';
import { IPageProps } from "../../../utils/types/types";
import { DepartmentForm } from "./components/DepartmentForm";

interface IAddDepartmentPageProps extends IPageProps { }

export const AddDepartmentPageInner: React.FC<IAddDepartmentPageProps & ConnectedProps<typeof connector>> = ({
	history,
	route,
	users,
	me,
	department,
	getListUsers: getListUsersAction,
	addDepartment: addDepartmentAction
}) => {

	const { resources } = route;
	const { data: thisMe } = me;

	useEffect(() => {
		const isPermitted = !!thisMe && !!resources && !!hasPermission(thisMe.permissions, resources);
		if (!isPermitted) history.push('/');
	});

	useEffect(() => {
		getListUsersAction();
	}, [getListUsersAction]);

	const addNewDepartment = (department: ICreateDepartment) => addDepartmentAction(department);

	return (
		<PageSkeleton mainLayout={true} pageTitle={route.title}>
			<Layout className="content-layout">
				<Spin spinning={department.isLoading}>
					<DepartmentForm users={users.data} onFormSubmitted={addNewDepartment} />
				</Spin>
			</Layout>
		</PageSkeleton>
	);
};

const mapStateToProps = (state: GlobalState) => ({
	users: state.users.usersList,
	department: state.department.AddDepartment,
	me: state.users.me,
});

const mapDispatchToProps = {
	getListUsers,
	addDepartment,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AddDepartmentPage = connector(AddDepartmentPageInner);
