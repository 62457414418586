import { CheckCircleOutlined, MailOutlined, PhoneOutlined, StopOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Modal, Row } from 'antd';
import Moment from 'moment';
import React, { useState } from 'react';
import { PermissionsHandler } from '../../../../../components/PermissionsHandler';
import { IMe, IToggleEnableUser, IUser } from '../../../../../utils/types/user';
import './detail-profile.less';

interface IDetailProfileProps {
  user: IUser;
  me: IMe;
  onToggleEnableUser: (terms: IToggleEnableUser) => void;
}
export const DetailProfile: React.FC<IDetailProfileProps> = ({
  user,
  me,
  onToggleEnableUser
}) => {

  const [isModalVisible, setModalVisible] = useState(false);

  const handleConfirmDisableProfile = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    onToggleEnableUser({
      id: user.id,
      status: user.enabled,
    });
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <div className='detail-profile'>
      <Row gutter={[16, 16]}>
        <Col xl={6} md={8} sm={10} xs={24} className='wraper-avatar'>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Avatar
                size={150}
                src={user.avatar ? user.avatar.url : undefined}
                icon={user.avatar ? undefined : <UserOutlined />}
              />
            </Col>
            {
              me.data && (
                <PermissionsHandler resourceName="User" blocPermission="CAN_ENABLE" userPermissions={me.data.permissions}>

                  <Col xs={24}>
                    <Button icon={user.enabled ? <StopOutlined /> : <CheckCircleOutlined />} type='primary' danger onClick={handleConfirmDisableProfile}>
                      {user.enabled ? "Désactiver" : "Activer"}
                    </Button>
                  </Col>
                </PermissionsHandler>
              )
            }

          </Row>
        </Col>
        <Col xl={18} md={16} sm={14} xs={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <h2>{user.firstName} {user.lastName}</h2>
            </Col>
            <Col xs={24}>
              <h3>{user.department.title}: {user.role.title}</h3>
            </Col>
            <Col xs={24}>
              <MailOutlined /> Adresse Email: <strong>{user.email}</strong>
            </Col>
            {
              !!user.phone && (
                <Col xs={24}>
                  <PhoneOutlined /> Téléphone: <strong>{user.phone}</strong>
                </Col>
              )
            }
            {
              !!user.cin && (
                <Col xs={24}>
                  N° CIN: <strong>{user.cin}</strong>
                </Col>
              )
            }
            {
              !!user.hiringDay && (
                <Col xs={24}>
                  Date d'embauche: <strong>{Moment(user.hiringDay).format('DD/MM/YYYY')}</strong>
                </Col>
              )
            }
            {
              !!user.registrationNumber && (
                <Col xs={24}>
                  Matricule: <strong>{user.registrationNumber}</strong>
                </Col>
              )
            }
            
          </Row>
        </Col>
      </Row>
      <Modal
        title={`Confirmation d${user.enabled ? 'e désactivation' : '\'activation'} du compte`}
        visible={isModalVisible}
        closable={false}
        footer={[
          (<Button key="no" type="primary" onClick={handleCancel}>Non</Button>),
          (<Button key="yes" type="primary" onClick={handleOk} danger icon={user.enabled ? <StopOutlined /> : <CheckCircleOutlined />}>
            { user.enabled ? "Désactiver" : "Activer"}
          </Button>)
        ]}
      >
        <p>{`Vous êtes sûr d${user.enabled ? 'e désactiver' : '\'activer'} le compte de ${user.firstName} ${user.lastName}`}</p>
      </Modal>
    </div>
  )
}
