import { Alert, Button, Col, DatePicker, Divider, Form, Input, Radio, Row, Space, TimePicker } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import { IAddLeave } from '../../../../../store/leave/types';
import { ICreateLeave } from '../../../../../utils/types/leave';

moment.locale('fr');

const { Item } = Form;

interface IExceptionalLeaveFormProps {
  addLeave: IAddLeave;
  onSubmitLeave: (leave: ICreateLeave) => void;
};

const warnings = [
  "La demande doit être dépoée d'avance: pas de régularisation après arrivée en retard.",
  "Toute demande dépassant les 2 heures, ou tout retour suite à une sortie exceptionnelle dépassant 2 heures sera considérée comme une congé et sera retiré du solde des congés payés.",
  "La demande de sortie exceptionnelle ne doit pas être considérée comme un acquis. Une seule demande par mois est tolérée comme exceptionnelle.",
];

export const ExceptionalLeaveForm: React.FC<IExceptionalLeaveFormProps> = ({
  addLeave,
  onSubmitLeave,
}) => {

  const [form] = Form.useForm();

  const { isLoading, isCreated, errors } = addLeave;

  const disabledHours = [0o0, 0o1, 0o2, 0o3, 0o4, 0o5, 0o6, 0o7, 19, 20, 21, 22, 23];

  const [isComingBack, setCompingBack] = useState(false);

  useEffect(() => {
    if (isCreated) {
      form.resetFields();
      setCompingBack(false);
    }
  }, [isCreated]);

  const handleExcepLeaveRepriseOptionChange = ({ target }: RadioChangeEvent) => setCompingBack(target.value);

  const handleDisabledHours = () => disabledHours;

  const handleFormSubmit = (values: any) => onSubmitLeave({
    ...values,
    startingDate: (values.startingDate as moment.Moment).format('YYYY-MM-DD HH:mm'),
    endingDate: isComingBack ? `${(values.startingDate as moment.Moment).format('YYYY-MM-DD')} ${(values.endingDate as moment.Moment).format('HH:mm')}` : null,
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFormSubmit}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Attention</Divider>
          <Space direction="vertical">
            {
              warnings.map(warning => <Alert key={warning} message={warning} banner />)
            }
          </Space>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Sollicite une sortie exceptionnelle</Divider>
        </Col>
      </Row>
      <Row gutter={[16, 16]} align="bottom">
        <Col lg={6} xs={24}>
          <Item
            name="startingDate"
            label="Date de début"
            rules={[
              { required: true, message: 'Veuillez saisir une date de début !' }
            ]}
          >
            <DatePicker
              showTime
              showHour
              format={'YYYY-MM-DD HH'}
              placeholder="Date / heure de sortie"
              disabledHours={handleDisabledHours}
            />
          </Item>
        </Col>
        <Col lg={8} xs={24}>
          <Item>
            <Radio.Group onChange={handleExcepLeaveRepriseOptionChange}>
              <Radio value={true}>Avec retour</Radio>
              <Radio value={false}>Sans retour dans la journée</Radio>
            </Radio.Group>
          </Item>
        </Col>
        {
          isComingBack && (
            <Col lg={6} xs={24}>
              <Item
                name="endingDate"
                label="Date de reprise"
                rules={[
                  { required: true, message: 'Veuillez saisir une date de reprise !' }
                ]}
              >
                <TimePicker
                  showHour
                  format={'HH'}
                  placeholder="Heure de retour"
                  disabledHours={handleDisabledHours}
                />
              </Item>
            </Col>
          )
        }
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Divider orientation="left">Motif de sortie exceptionnelle</Divider>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={8} xs={24}>
          <Item
            name="reason"
            label="Votre motif"
            rules={[
              { required: true, message: 'Veuillez entrer votre motif !' }
            ]}
          >
            <Input.TextArea placeholder="Justification..." autoSize={{ minRows: 2, maxRows: 6 }} />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col>
          <Button loading={isLoading} htmlType="submit" type="primary">Envoyer la demande</Button>
        </Col>
      </Row>
    </Form >
  );
};
