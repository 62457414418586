import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Table, Tabs, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { PermissionsHandler } from '../../../../../components/PermissionsHandler';
import { IDepartment } from '../../../../../utils/types/department';
import { IMe, IUser } from '../../../../../utils/types/user';

const { TabPane } = Tabs;
const { Column } = Table;
const { Text } = Typography;

interface IListDepartmentsProps {
  departments: IDepartment[];
  team: IUser[];
  isLoading: boolean;
  me: IMe;
  onDepartmentTabChange: (id: string) => void;
  onUpdateModalVisibility: (state: boolean) => void;
};

export const ListDepartments: React.FC<IListDepartmentsProps> = ({
  departments,
  team,
  isLoading,
  me,
  onDepartmentTabChange,
  onUpdateModalVisibility,
}) => {

  const isMentioned = (field?: string | Date, isDate?: boolean) => {
    return field ? <Text>{isDate ? moment(field).format('DD/MM/YYYY') : field}</Text> : <Text className="not-mentioned">- Pas mentionné -</Text>
  };

  return (
    <Tabs
      type="card"
      className='list-departments'
      onChange={onDepartmentTabChange}
    >

      {departments.map((department) => (
        <TabPane
          tab={department.title}
          key={department.id}
          forceRender
        >

          <Row justify="space-between" gutter={[32, 32]}>
            <Col xs={18}>
              <span>{department.description}</span>
            </Col>
            {
              me.data &&
              <PermissionsHandler resourceName="Department" blocPermission="CAN_PUT" userPermissions={me.data.permissions}>
                <Col xs={6}>
                  <Row justify="end">
                    <Col>
                      <Tooltip title="Modifier ce département" placement="topRight">
                        <Link to={`/departments/update/${department.id}`}>
                          <Button icon={<EditOutlined />} type="primary" />
                        </Link>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              </PermissionsHandler>
            }
          </Row>

          <Table<IUser>
            dataSource={team}
            pagination={{
              hideOnSinglePage: true,
            }}
            scroll={{
              x: true,
              scrollToFirstRowOnChange: true,
            }}
            loading={isLoading}
            rowKey={(record) => `key-${record.id}`}
          >
            <Column
              key="avatar"
              fixed="left"
              width={32}
              render={
                (user) => (
                  <Avatar
                    shape="square"
                    src={!!user.avatar && `${process.env.REACT_APP_BASE_URL}${user.avatar.url}`}
                    icon={!user.avatar && <UserOutlined />}
                  />
                )
              }
            />

            <Column
              title="Nom complet"
              key="fullName"
              render={
                (user) => <Text>{user.firstName} {user.lastName}</Text>
              }
            />

            <Column
              title="E-mail"
              key="email"
              render={
                (user) => <Text>{user.email}</Text>
              }
            />

            <Column
              title="Date d'embauche"
              key="hiringDay"
              render={
                (user) => isMentioned(moment(user.hiringDay).format('DD/MM/YYYY'), false)
              }
            />

            <Column
              title="Téléphone"
              key="phone"
              render={
                (user) => isMentioned(user.phone)
              }
            />

            <Column
              title="Rôle"
              key="role"
              render={
                (user) => <Tag color={user.isResponsible ? 'green' : 'default'} className="upper">{user.role.title}</Tag>
              }
            />

          </Table>
        </TabPane>
      ))
      }
    </Tabs >
  );
};
