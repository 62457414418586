import { CreditCardOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { ICounter } from '../../../../../../utils/types/leave';

const { Text, Title } = Typography;

interface ILeavesBalanceProps {
  counter?: ICounter;
};

export const LeavesBalance: React.FC<ILeavesBalanceProps> = ({
  counter,
}) => {

  const [isPopoverVisible, setPopoverVisibility] = useState(false);

  return (
    <Tooltip title="Crédit de congés">
      <Popover
        title={<Title level={4}>Crédit de congés</Title>}
        content={(
          <>
            <Row gutter={[0, 16]} justify="space-between">
              <Col>
                <Text>Crédit annuel</Text>
              </Col>
              <Col>
                <Text type="success" strong>{counter ? counter.annualCredit : 0}</Text>
              </Col>
            </Row>

            <Row gutter={[0, 16]} justify="space-between">
              <Col>
                <Text>Crédit maladie</Text>
              </Col>
              <Col>
                <Text type="success" strong>{counter ? counter.sickLeaveCredit : 0}</Text>
              </Col>
            </Row>
          </>
        )}
        placement="bottomRight"
        trigger="click"
        visible={isPopoverVisible}
        onVisibleChange={setPopoverVisibility}
        overlayClassName="header-popover"
      >
        <Button icon={<CreditCardOutlined />} />
      </Popover>
    </Tooltip>
  );
};
