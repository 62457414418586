import { Action } from "redux";
import { ICounter } from "../../utils/types/leave";
import { IErrors, IStateInitializer } from "../../utils/types/types";
import { IMe, IToggleEnableUser, IUpdateUser, IUser } from "../../utils/types/user";

export interface IMeResponse {
  me: IUser;
};

export interface IListUsers extends IStateInitializer {
  data: IUser[];
};

export interface ISingleUser extends IStateInitializer {
  data: IUser | null;
};

export interface IUsersState {
  usersList: IListUsers;
  me: IMe;
  user: ISingleUser;
  update: IStateInitializer;
  toggleEnable: IStateInitializer;
};

/**
 * List users
 */
export const USERS_GET_LIST = 'USERS_GET_LIST';
export type USERS_GET_LIST = typeof USERS_GET_LIST;

export const USERS_GET_LIST_SUCCESS = 'USERS_GET_LIST_SUCCESS';
export type USERS_GET_LIST_SUCCESS = typeof USERS_GET_LIST_SUCCESS;

export const USERS_GET_LIST_ERROR = 'USERS_GET_LIST_ERROR';
export type USERS_GET_LIST_ERROR = typeof USERS_GET_LIST_ERROR;

export interface IGetListUsersAction extends Action<USERS_GET_LIST> { }

export interface IGetListUsersSuccessAction extends Action<USERS_GET_LIST_SUCCESS> {
  payload: IUser[];
}

export interface IGetListUsersErrorAction extends Action<USERS_GET_LIST_ERROR> {
  payload: IErrors;
}

/**
 * Get user
 */
export const GET_USER = 'GET_USER';
export type GET_USER = typeof GET_USER;

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export type GET_USER_SUCCESS = typeof GET_USER_SUCCESS;

export const GET_USER_ERROR = 'GET_USER_ERROR';
export type GET_USER_ERROR = typeof GET_USER_ERROR;

export interface IGetUserAction extends Action<GET_USER> {
  payload: string;
}

export interface IGetUserSuccessAction extends Action<GET_USER_SUCCESS> {
  payload: IUser;
}

export interface IGetUserErrorAction extends Action<GET_USER_ERROR> {
  payload: IErrors;
}

/**
 * Update user
 */
export const USER_UPDATE = 'USER_UPDATE';
export type USER_UPDATE = typeof USER_UPDATE;

export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export type USER_UPDATE_SUCCESS = typeof USER_UPDATE_SUCCESS;

export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';
export type USER_UPDATE_ERROR = typeof USER_UPDATE_ERROR;

export interface IUpdateUserAction extends Action<USER_UPDATE> {
  payload: IUpdateUser;
};

export interface IUpdateUserSuccessAction extends Action<USER_UPDATE_SUCCESS> { };

export interface IUpdateUserErrorAction extends Action<USER_UPDATE_ERROR> {
  payload: IErrors;
};

/**
 * Enable/Desable user
 */
export const TOGGLE_ENABLE_USER = 'TOGGLE_ENABLE_USER';
export type TOGGLE_ENABLE_USER = typeof TOGGLE_ENABLE_USER;

export const TOGGLE_ENABLE_USER_SUCCESS = 'TOGGLE_ENABLE_USER_SUCCESS';
export type TOGGLE_ENABLE_USER_SUCCESS = typeof TOGGLE_ENABLE_USER_SUCCESS;

export const TOGGLE_ENABLE_USER_ERROR = 'TOGGLE_ENABLE_USER_ERROR';
export type TOGGLE_ENABLE_USER_ERROR = typeof TOGGLE_ENABLE_USER_ERROR;

export interface IToggleEnableUserAction extends Action<TOGGLE_ENABLE_USER> {
  payload: IToggleEnableUser;
}

export interface IToggleEnableUserSuccessAction extends Action<TOGGLE_ENABLE_USER_SUCCESS> { }

export interface IToggleEnableUserErrorAction extends Action<TOGGLE_ENABLE_USER_ERROR> {
  payload: IErrors;
}

/**
 * Connected user
 */
export const USER_GET_ME = 'USER_GET_ME';
export type USER_GET_ME = typeof USER_GET_ME;

export const USER_GET_ME_SUCCESS = 'USER_GET_ME_SUCCESS';
export type USER_GET_ME_SUCCESS = typeof USER_GET_ME_SUCCESS;

export const USER_GET_ME_ERROR = 'USER_GET_ME_ERROR';
export type USER_GET_ME_ERROR = typeof USER_GET_ME_ERROR;

export interface IUserGetMeAction extends Action<USER_GET_ME> { };

export interface IUserGetMeSuccessAction extends Action<USER_GET_ME_SUCCESS> {
  payload: IUser;
};

export interface IUserGetMeErrorAction extends Action<USER_GET_ME_ERROR> {
  payload: IErrors;
};

// User logout
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export type USER_LOGGED_OUT = typeof USER_LOGGED_OUT;

export interface IUserLoggedOutAction extends Action<USER_LOGGED_OUT> { };

/**
 * Connected user counter
 */
export const USER_GET_COUNTER = 'USER_GET_COUNTER';
export type USER_GET_COUNTER = typeof USER_GET_COUNTER;

export const USER_GET_COUNTER_SUCCESS = 'USER_GET_COUNTER_SUCCESS';
export type USER_GET_COUNTER_SUCCESS = typeof USER_GET_COUNTER_SUCCESS;

export const USER_GET_COUNTER_ERROR = 'USER_GET_COUNTER_ERROR';
export type USER_GET_COUNTER_ERROR = typeof USER_GET_COUNTER_ERROR;

export interface IUserGetCounterAction extends Action<USER_GET_COUNTER> {
  payload: string;
};

export interface IUserGetCounterSuccessAction extends Action<USER_GET_COUNTER_SUCCESS> {
  payload: ICounter;
};

export interface IUserGetCounterErrorAction extends Action<USER_GET_COUNTER_ERROR> {
  payload: IErrors;
};

export type UsersActionTypes =
  IGetListUsersAction
  | IGetListUsersSuccessAction
  | IGetListUsersErrorAction
  | IGetUserAction
  | IGetUserSuccessAction
  | IGetUserErrorAction
  | IUpdateUserAction
  | IUpdateUserSuccessAction
  | IUpdateUserErrorAction
  | IToggleEnableUserAction
  | IToggleEnableUserSuccessAction
  | IToggleEnableUserErrorAction
  | IUserGetMeAction
  | IUserGetMeSuccessAction
  | IUserGetMeErrorAction
  | IUserLoggedOutAction
  | IUserGetCounterAction
  | IUserGetCounterSuccessAction
  | IUserGetCounterErrorAction;