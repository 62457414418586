export const deviceBreakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const clientId = '1057035499071-dt79c4p8geih6cri6etdciodf2us3cq4.apps.googleusercontent.com';

export const exceptionalLeave = "sortie exceptionnelle";

export const exceptionalLeaveSlug = 'SORTIE_EXCEPTIONNELLE';

export const roles = {
  manager: 'ROLE_DIRECTEUR',
  drh: 'ROLE_DRH',
  responsible: 'ROLE_RESPONSABLE_DE_POLE',
  emplyee: 'ROLE_EMPLOYE',
};