import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, message, Radio, Row, Select, Upload } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../context/AuthContext';
import { IDepartmentState } from '../../../../../store/department/types';
import { IRoleState } from '../../../../../store/role/types';
import { allowNumbersOnly } from '../../../../../utils/input-number-utils';
import { IAuthContext } from '../../../../../utils/types/types';
import { IUpdateUser, IUser } from '../../../../../utils/types/user';
import { roles as rolesSlugs } from '../../../../../utils/vars';

const { Item } = Form;
const { Option } = Select;
const { Group } = Radio;

interface IUpdateFormProps {
  connectedUserRole?: string;
  user: IUser;
  roles: IRoleState;
  departments: IDepartmentState,
  onUpdateFormSubmitted: (updatedUser: IUpdateUser) => void;
}

/**
 * ========================= Upload functions =========================
 */
const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: any) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Vous ne pouvez télécharger que des fichiers JPG / PNG!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("L'image doit être inférieure à 2 Mo!");
  }
  return isJpgOrPng && isLt2M;
};
/**
 * ========================= Upload functions =========================
 */

export const UpdateForm: React.FC<IUpdateFormProps> = ({
  connectedUserRole,
  user,
  roles,
  departments,
  onUpdateFormSubmitted,
}) => {

  const { state: authState }: IAuthContext = useContext(AuthContext);

  const [form] = Form.useForm();

  const [hasHighRole, setHighRole] = useState(false);

  const [department, setDepartment] = useState<string | undefined>();
  const [role, setRole] = useState<string | undefined>();
  const [hiringDay, setHiringDay] = useState<null | moment.Moment>(null);
  const [file, setFile] = useState({
    loading: false,
    imageUrl: '',
  });

  useEffect(() => {
    if (connectedUserRole)
      setHighRole(connectedUserRole === rolesSlugs.drh || connectedUserRole === rolesSlugs.manager);
  }, [connectedUserRole, rolesSlugs]);

  useEffect(() => {
    if (user.avatar) setFile({
      ...file,
      imageUrl: `${process.env.REACT_APP_BASE_URL}${user.avatar.url}`
    });
  }, [user]);

  const handlehiringChange = (date: moment.Moment | null) => setHiringDay(date);

  const disableFutureDays = (current: moment.Moment | null) => {
    if (!current) return false;
    return current > moment().endOf('day');
  };
  const diableExitDay = (current: moment.Moment | null) => {
    // Can not select days before current
    if (!hiringDay || !current) return false;
    return hiringDay > current;
  };

  const handleDepartmentChange = (department: string) => setDepartment(department);
  const handleRoleChange = (role: string) => setRole(role);

  const handleFileChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setFile({
        ...file,
        loading: true,
      });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl: string) =>
        setFile({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  const handleFormSubmit = (values: any) => {
    if (values) onUpdateFormSubmitted({
      ...values,
      id: user.id,
      department,
      role,
      avatar: values.avatar && `/files/${values.avatar[0].response.id}`,
      birthday: (values.birthday as moment.Moment).format('YYYY-MM-DD HH:mm:ss'),
      hiringDay: (values.hiringDay as moment.Moment).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const uploadButton = (
    <div>
      {file.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Avatar</div>
    </div>
  );

  const uploadProps = {
    name: 'file',
    action: `${process.env.REACT_APP_BASE_URL}/files`,
    listType: 'picture',
    headers: {
      authorization: `Bearer ${authState.token}`,
      Accept: 'application/json',
    },
  };

  const UploadedAvatar = (e: any) => e && e.fileList.slice(-1);

  return (
    <Form
      onFinish={handleFormSubmit}
      form={form}
      layout="vertical"
      initialValues={{
        ...user,
        avatar: user.avatar && user.avatar.url,
        birthday: user.birthday ? moment(user.birthday) : null,
        hiringDay: user.hiringDay ? moment(user.hiringDay) : null,
        role: user.role ? user.role.title : null,
        department: user.department ? user.department.title : null,
      }}
    >
      <Row>
        <Col>
          <Item>
            <h4>Modifier le profil de {user.firstName} {user.lastName}</h4>
          </Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <Item
            name='avatar'
            valuePropName='fileList'
            validateTrigger='onChange'
            getValueFromEvent={UploadedAvatar}
          >
            <Upload
              {...uploadProps}
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept=".jpg,.png"
              beforeUpload={beforeUpload}
              onChange={handleFileChange}
            >{file.imageUrl ? <img src={file.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          E-mail: <strong>{user.email}</strong>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Item
            name="gendre"
            label="Sexe"
            rules={[{ required: true, message: 'Veuillez choisir le sexe !' }]}
          >
            <Group>
              <Radio.Button value="Homme">Masculin</Radio.Button>
              <Radio.Button value="Femme">Féminin</Radio.Button>
            </Group>
          </Item>
        </Col>
        {
          hasHighRole && (
            <Col md={12} xs={24}>
              <Item
                name="registrationNumber"
                label="Matricule"
                rules={[
                  { required: true, message: "Ce champ est requis !" }
                ]}
              >
                <Input type="text" placeholder="Matricule ..." />
              </Item>
            </Col>
          )
        }
      </Row>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Item
            name="firstName"
            label="Prénom"
            rules={[
              { required: true, message: "Ce champ est requis !" }
            ]}
          >
            <Input type="text" placeholder="Nouveau prénom ..." />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name="lastName"
            label="Nom"
            rules={[
              { required: true, message: "Ce champ est requis !" }
            ]}
          >
            <Input type="text" placeholder="Nouveau nom ..." />
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Item name="phone" label="Numéro du téléphone">
            <Input type="phone" placeholder="Nouveau numéro du téléphone ..." />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name="cin"
            label="N° CIN"
            validateTrigger={['onBlur', 'onChange']}
            rules={[
              { required: true, message: 'Veuillez saisir le numéro de la carte d\'identité' },
              { max: 8, min: 8, message: 'Le numéro d\'identiée doit être 8 chiffres !' },
              { validator: allowNumbersOnly },
            ]}
          >
            <Input minLength={8} maxLength={8} placeholder="Nouveau numéro carte d'identitée ..." />
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Item
            name="birthday"
            label="Date de naissance"
            validateTrigger="onBlur"
            rules={[
              { required: true, message: 'Veuillez entrer une date de naissance !' }
            ]}
          >
            <DatePicker
              placeholder="Date de naissance"
              disabledDate={disableFutureDays}
            />
          </Item>
        </Col>
        {
          hasHighRole && (
            <Col md={12} xs={24}>
              <Item
                name="hiringDay"
                label="Date d'embauche"
                validateTrigger="onBlur"
                rules={[
                  { type: 'object', required: true, message: 'Veuillez entrer une date d\'embauche !' }
                ]}
              >
                <DatePicker placeholder="Date d'embauche" onChange={handlehiringChange} />
              </Item>
            </Col>
          )
        }

      </Row>
      {
        hasHighRole && (
          <>
            <Row gutter={[16, 16]}>
              <Col md={8} xs={24}>
                <Item
                  name="department"
                  label="Département"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: 'Veuillez choisir un département !' }
                  ]}
                >
                  <Select placeholder="Sélectionner le département" onChange={handleDepartmentChange}>
                    {
                      departments.departmentList.data.map(dep => <Option key={dep.id} value={`/departments/${dep.id}`}>{dep.title}</Option>)
                    }
                  </Select>
                </Item>
              </Col>
              <Col md={8} xs={24}>
                <Item
                  name="role"
                  label="Rôle"
                  validateTrigger="onBlur"
                  rules={[
                    { required: true, message: 'Veuillez choisir un rôle !' }
                  ]}
                >
                  <Select placeholder="Sélectionner le rôle" loading={roles.isLoading} onChange={handleRoleChange}>
                    {
                      roles.rolesList.map(role => <Option key={role.slug} value={`/roles/${role.id}`}>{role.title}</Option>)
                    }
                  </Select>
                </Item>
              </Col>
              <Col md={8} xs={24}>
                <Item
                  name="occupation"
                  label="Poste"
                  rules={[
                    { required: true, message: "Ce champ est requis !" }
                  ]}
                >
                  <Input type="text" placeholder="Veuillez entrer un poste ..." />
                </Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
            </Row>

            <Row gutter={[16, 16]}>
              <Col md={12} xs={24}>
                <Item
                  name="totalDaysCongeLegalAnnual"
                  label="Solde de congé légal annuel"
                  rules={[
                    { required: true, message: "Ce champ est requis !" }
                  ]}
                >
                  <Input type="text" placeholder="Veuillez entrer un solde de congé légal annuel ..." />
                </Item>
              </Col>
              <Col md={12} xs={24}>
                <Item
                  name="totalDaysSickLeaveCredit"
                  label="Solde de congé de maladie"
                  rules={[
                    { required: true, message: "Ce champ est requis !" }
                  ]}
                >
                  <Input type="text" placeholder="Veuillez entrer un solde de congé de maladie ..." />
                </Item>
              </Col>
            </Row>
          </>
        )
      }

      <Row gutter={[16, 16]}>
        <Col lg={8} md={8} xs={24}>
          <Button htmlType="submit" type="primary">Modifier</Button>
        </Col>
      </Row>
    </Form>
  );
};
