import { message } from "antd";
import { AxiosResponse } from "axios";
import { put, takeLatest } from "redux-saga/effects";
import { getMeRequest, getUserCounterRequest, getUserRequest, getUsersRequest, toggleEnableUserRequest, updateUserRequest } from '../../api/requests';
import { ICounter } from '../../utils/types/leave';
import { IUser } from "../../utils/types/user";
import { getListUsersError, getListUsersSuccess, getMeError, getMeSuccess, getUser as getUserAction, getUserError, getUserSuccess, meGetCounter, meGetCounterError, meGetCounterSuccess, toggleEnableUserError, toggleEnableUserSuccess, updateUserError, updateUserSuccess } from "./actions";
import { GET_USER, IGetUserAction, IMeResponse, IToggleEnableUserAction, IUpdateUserAction, IUserGetCounterAction, TOGGLE_ENABLE_USER, USERS_GET_LIST, USER_GET_COUNTER, USER_GET_ME, USER_UPDATE } from "./types";

/**
 * Get users
 */
function* getUsers() {
  try {
    const res: AxiosResponse<IUser[]> = yield getUsersRequest();
    yield put(getListUsersSuccess(res.data));
  } catch (error) {
    yield put(getListUsersError(error));
  };
};

export function* watchGetUsers() {
  yield takeLatest(USERS_GET_LIST, getUsers);
};

/**
 * GET USER
 */
function* getUser({ payload: id }: IGetUserAction) {

  try {
    const res: AxiosResponse<IUser> = yield getUserRequest(id);
    yield put(getUserSuccess(res.data));
  } catch (error) {
    yield put(getUserError(error));
  };
};

export function* watchGetUser() {
  yield takeLatest(GET_USER, getUser);
};

/**
 * Update user
 */
function* updateUser({ payload: user }: IUpdateUserAction) {
  try {
    yield updateUserRequest(user);
    yield put(updateUserSuccess());
    message.destroy();
    message.success(`Le profil de ${user.firstName} ${user.lastName} a été mis à jour avec succès.`, 5);
  } catch (error) {
    yield put(updateUserError(error));
  };
};

export function* watchUpdateUser() {
  yield takeLatest(USER_UPDATE, updateUser);
};

/**
 * Enable/Desable user
 */
function* toggleEnableUser({ payload: terms }: IToggleEnableUserAction) {
  try {
    yield toggleEnableUserRequest(terms.id);
    yield put(toggleEnableUserSuccess());
    yield put(getUserAction(terms.id));
    message.destroy();
    message.success(`Le profil a été ${terms.status ? 'désactivé' : 'activé'} avec succès.`, 5);
  } catch (error) {
    yield put(toggleEnableUserError(error));
  };
};

export function* watchToggleEnableUser() {
  yield takeLatest(TOGGLE_ENABLE_USER, toggleEnableUser);
};

/**
 * Get Me
 */
function* getMeSaga() {
  try {
    const res: AxiosResponse<IMeResponse> = yield getMeRequest();
    yield put(getMeSuccess(res.data.me));
    yield put(meGetCounter(res.data.me.id));
  } catch (error) {
    yield put(getMeError(error));
  }
};

export function* watchGetMe() {
  yield takeLatest(USER_GET_ME, getMeSaga);
};

/**
 * Me get counter
 */
function* getMeCounter({ payload: userId }: IUserGetCounterAction) {
  try {
    const res: AxiosResponse<ICounter> = yield getUserCounterRequest(userId);
    yield put(meGetCounterSuccess(res.data));
  } catch (error) {
    yield put(meGetCounterError(error));
  }
};

export function* watchGetMeCounter() {
  yield takeLatest(USER_GET_COUNTER, getMeCounter);
};
