import React, { useEffect, useState } from 'react';
import { IUserPermissions } from '../../utils/types/user';

interface IPermissionsHandlerProps {
  resourceName: string;
  blocPermission: string;
  userPermissions: IUserPermissions;
  connectedUserId?: string;
  ownerId?: string;
};

export const PermissionsHandler: React.FC<IPermissionsHandlerProps> = ({
  children,
  resourceName,
  blocPermission,
  userPermissions,
  connectedUserId,
  ownerId,
}) => {

  const [isPermitted, setPermitted] = useState(false);

  useEffect(() => {
    if (!!userPermissions) {
      const hasPermissions = userPermissions[resourceName]?.find(resource => resource.includes(blocPermission)) || [];

      const hasPosession = hasPermissions?.includes('ALL') || hasPermissions?.includes(null) || (connectedUserId === ownerId && hasPermissions?.includes('OWN'));

      setPermitted(hasPosession);
    }
  }, [userPermissions, resourceName, blocPermission]);

  return <>{isPermitted && children}</>;
};
